/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

.align-middle {
  align-items: center !important;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gTD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3g3D_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gbD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gfD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gTD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3g3D_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gbD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gfD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gnD_vx3rCs.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gTD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3g3D_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gbD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gfD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmYUtfCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmYUtfABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmYUtfCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmYUtfBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmYUtfCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmYUtfChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmYUtfBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Global */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Inputs */
/* Bans */
/* Header */
/* header ul li.dropdown {
    margin-right: 0;
  } */
/* Login */
/* Footer */
/* Homepage */
/*!
   * Bootstrap Grid v4.4.0 (https://getbootstrap.com/)
   * Copyright 2011-2019 The Bootstrap Authors
   * Copyright 2011-2019 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
   */
/* idea */
/* Alert override */
/* .historic-tickets {
    max-height: 300px;
  } */
html {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif !important;
  background-color: #f7f7f7 !important;
  height: auto !important;
  width: auto !important;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif !important;
  background-color: #f7f7f7 !important;
  height: auto !important;
  width: auto !important;
}
main {
  min-height: calc(100vh - 257px);
  position: relative;
}
h1 {
  font-weight: 800 !important;
  color: #282828;
}
h2 {
  font-weight: 800 !important;
  color: #282828;
}
label {
  display: block;
  font-weight: 600;
  margin: 5px 0;
}
.ant-progress-bg {
  background-color: #439aa4 !important;
}
.ant-btn-primary {
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  background-color: #439aa4 !important;
  border-radius: 3px;
  color: white;
  transition: all 0.3s;
  border: 0 !important;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  &:hover {
    background-color: #357880 !important;
  }
}
.ant-btn {
  font-size: 13px !important;
  > .anticon {
    vertical-align: middle;
  }
}
.home-banner {
  background-position: bottom;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.videoInsert {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute; /* magic sauce */
}
.btn {
  padding: 10px 18px;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  background-color: #439aa4;
  border-radius: 3px;
  font-size: 13px;
  color: white;
  margin: 0 3px;
  transition: all 0.3s;
  border: 0 !important;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  &:hover {
    background-color: #2f6c73;
    color: white;
  }
}
.ant-btn.imgur {
  background-color: #1bb76e !important;
  color: white !important;
  border-color: #1bb76e !important;
}
.btn.basic {
  background-color: rgb(224, 225, 226);
  color: #616161;
  box-shadow: 0 2px rgb(197, 197, 197);
  &:hover {
    background-color: rgb(197, 197, 197);
  }
}
.minHeight {
  min-height: calc(100vh - 575px);
}
.text-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.white-container {
  padding: 10px;
  padding-bottom: 50px !important;
}
.giftCode {
  font-size: 30px;
  text-align: center;
  pre {
    margin-bottom: 0;
  }
}
.leaflet-container {
  height: 642px !important;
  max-width: 100% !important;
  overflow: hidden;
  background-color: #1aa9d0 !important;
}
.darkSection {
  background-color: #282828;
  padding: 40px 20px 70px 20px;
  color: white;
  h2 {
    color: white;
    margin-bottom: 50px;
  }
  h3 {
    color: #ccc;
  }
}
.lightSection {
  padding: 40px 20px;
  color: #282828;
  margin-top: 40px;
  .card {
    background-color: #fcfcfc;
    padding: 0;
    box-shadow: hsl(0, 0%, 85%) 0 0 16px;
    transition: all 0.3s;
    &:hover {
      margin-top: -5px;
    }
    h4 {
      margin: 0;
      transition: all 0.3s;
      padding: 10px;
      font-size: 14px;
      font-weight: 600;
    }
    i {
      color: #ccc;
      font-size: 17px;
      margin: 0;
      transition: all 0.3s;
    }
  }
  h2 {
    color: #282828;
    margin-bottom: 20px;
  }
  h3 {
    margin: 0;
    padding: 0;
    color: #797979;
    font-weight: 600;
    margin-bottom: 30px;
  }
}
.cardToggler {
  img {
    width: 100%;
    max-height: 175px;
    overflow: hidden;
    height: auto;
  }
  .toggler {
    top: 11px;
    right: 10px;
    position: absolute;
  }
}
.cardContent {
  border-top: 1px solid #e7e7e7;
  padding-top: 10px;
  text-align: left;
  font-size: 13px;
  padding: 10px;
  img {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
  }
}
.text-left {
  text-align: left !important;
}
.card {
  background-color: #202020;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 3px;
  .value {
    color: #439aa4;
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0;
  }
  img {
    object-fit: cover;
  }
  // svg {
  //   font-size: 50px;
  //   margin-bottom: 10px;
  //   fill: #f9f9f9;
  // }
  .caption {
    font-size: 13px;
    color: #ccc;
  }
}
.loader {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-top: 4px solid #439aa4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}
.label {
  font-weight: 600;
  background-color: #ccc;
  font-weight: 400;
  font-size: 11px;
  padding: 3px 6px;
  border-radius: 3px;
  margin: 6px;
}
.label.green {
  color: white;
  background-color: #27ae60;
}
.submit-feedback {
  margin-top: -65px;
  z-index: 100;
  margin-bottom: 20px;
  button {
    font-size: 18px !important;
    padding: 15px 40px !important;
    height: auto !important;
  }
}
.serverStatus {
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
  color: white;
  text-transform: uppercase;
  .online {
    color: #27ae60;
    text-transform: uppercase;
    margin-right: 10px;
  }
  .cityName {
    margin-right: 10px;
  }
  .playerCount {
    margin-right: 10px;
  }
  .label {
    background-color: transparent;
    padding: 0;
    color: rgb(231, 231, 231);
  }
}
.metaSteam {
  font-size: 11px !important;
  color: #ccc;
}
.meta {
  font-size: 12px !important;
  margin-bottom: 15px;
  P {
    margin: 0;
  }
}
.message {
  position: relative;
  background: #f8f8f9;
  padding: 0.6rem;
  line-height: 1.4285em;
  border-radius: 3px;
  font-size: 14px;
}
.negative.message {
  background-color: #fff6f6;
  color: #9f3a38;
}
.info.message {
  background-color: #f8ffff;
  color: #276f86;
}
.input {
  padding: 10px;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ecf0f1;
  font-family: "Montserrat", sans-serif;
}
.input-button {
  .input {
    border-radius: 3px 0 0 3px;
    margin: 0 !important;
    width: 100%;
  }
  .btn {
    border-radius: 0 3px 3px 0;
    box-shadow: none !important;
    margin: 0 !important;
  }
}
.cityMeta {
  color: rgb(133, 133, 133);
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}
.key {
  font-size: 12px;
  span {
    font-weight: 600;
    margin-right: 10px;
  }
}
.banSegment {
  background-color: #ecf0f1;
  padding: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  .banSegment_id {
    font-weight: bold;
    flex-basis: 10%;
  }
  .banSegment_active {
    flex-basis: 10%;
  }
  .banSegment_reason {
    flex-basis: 58%;
  }
  .banSegment_time {
    flex-basis: 22%;
    font-size: 12px;
  }
  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
}
.banSegment.div {
  display: cell;
}
code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
header {
  display: block;
  display: block;
  z-index: 999;
  background-color: #616161;
  top: 0;
  left: 0;
  width: 100%;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 10px;
      align-self: center;
      a {
        text-decoration: none;
        padding: 10px 5px;
        border-radius: 3px;
        font-size: 13px;
        color: white;
        font-weight: 500;
        transition: all 0.3s;
        &:hover {
          color: #439aa4;
        }
      }
      a.active {
        color: #439aa4;
      }
    }
    li.dropdown {
      a {
        svg {
          font-size: 8px;
          margin-left: 6px;
          color: rgba(255, 255, 255, 0.4);
        }
      }
      &:hover {
        > a {
          color: #2f6c73 !important;
        }
        ul.dropdown {
          display: block;
        }
      }
      ul.dropdown {
        display: none;
        position: fixed;
        background-color: #282828;
        border-radius: 3px;
        padding-top: 15px;
        li {
          display: block;
          margin: 0;
          a {
            display: block;
            padding: 10px;
          }
          + {
            li {
              margin-top: -5px;
            }
          }
        }
      }
    }
    li.facebookNav {
      margin-left: 0 !important;
      a {
        background-color: #4267b2;
        color: white;
        padding: 7px;
        display: flex;
        &:hover {
          filter: brightness(85%);
        }
        span {
          svg {
            display: inline-block;
            align-self: center;
            height: auto;
            width: 1.5rem;
          }
        }
      }
    }
    li.twitterNav {
      margin-left: 0 !important;
      a {
        background-color: #14171A;
        color: white;
        padding: 7px;
        display: flex;
        &:hover {
          filter: brightness(85%);
        }
        span {
          svg {
            display: inline-block;
            align-self: center;
            height: auto;
            width: 1.5rem;
          }
        }
      }
    }
    li.linkedinNav {
      margin-left: 0 !important;
      a {
        background-color: #0077b5;
        color: white;
        padding: 7px;
        display: flex;
        &:hover {
          filter: brightness(85%);
        }
        span {
          svg {
            display: inline-block;
            align-self: center;
            height: auto;
            width: 1.5rem;
          }
        }
      }
    }
    li.instagramNav {
      margin-left: 0 !important;
      a {
        background-color: #C13584 ;
        color: white;
        padding: 7px;
        display: flex;
        &:hover {
          filter: brightness(85%);
        }
        span {
          svg {
            display: inline-block;
            align-self: center;
            height: auto;
            width: 1.5rem;
          }
        }
      }
    }
    li.phoneNav {
      margin-left: 0 !important;
      a {
        background-color: #1cc830;
        color: white;
        padding: 7px;
        display: flex;
        &:hover {
          filter: brightness(85%);
        }
        span {
          svg {
            display: inline-block;
            align-self: center;
            height: auto;
            width: 1.5rem;
          }
        }
      }
    }
    li.mailNav {
      margin-left: 0 !important;
      a {
        background-color: #5c7cfc;
        color: white;
        padding: 7px;
        display: flex;
        &:hover {
          filter: brightness(85%);
        }
        span {
          svg {
            display: inline-block;
            align-self: center;
            height: auto;
            width: 1.5rem;
          }
        }
      }
    }
    li.whatsappNav {
      margin-left: 0 !important;
      a {
        background-color: #54cc5c;
        color: white;
        padding: 7px;
        display: flex;
        &:hover {
          filter: brightness(85%);
        }
        span {
          svg {
            display: inline-block;
            align-self: center;
            height: auto;
            width: 1.5rem;
          }
        }
      }
    }
  }
  .ant-avatar {
    height: 36px;
    width: 36px;
  }
}
.ant-avatar {
  background-color: rgba(0, 0, 0, 0.1);
  color: #ccc;
}
.nav_divider {
  background-color: rgba(0, 0, 0, 0.1);
  display: block;
  height: 30px;
  margin-top: 3px;
  width: 1px;
}
.ant-statistic-content-prefix {
  color: #439aa4;
}
.avatar {
  img {
    max-height: 30px !important;
    width: 30px !important;
  }
}
.avatarStream {
  max-height: 30px !important;
  width: 30px !important;
}
a.statusNav.down {
  background-color: #c0392b;
  color: white;
  padding: 12px;
  display: flex;
  cursor: pointer;
  width: 36px;
  height: 36px;
}
a.statusNav.online {
  background-color: #27ae60;
  color: white;
  padding: 12px;
  display: flex;
  cursor: pointer;
  width: 36px;
  height: 36px;
}
a.userLogin {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 12px;
  display: flex;
}
.headingServerStatus {
  font-size: 13px !important;
  .city {
    font-size: 12px !important;
    .status {
      margin-right: 5px;
      font-weight: 600;
    }
    .queue {
      color: #ccc;
      margin-left: 5px;
    }
    .online {
      background-color: #27ae60;
      color: white;
      border-radius: 3px;
      padding: 1px 4px;
      margin-right: 5px;
      font-size: 9px;
      text-transform: uppercase;
    }
    .offline {
      background-color: #c0392b;
      color: white;
      border-radius: 3px;
      padding: 1px 4px;
      margin-right: 5px;
    }
  }
}
.online {
  color: #27ae60;
}
.issue {
  color: #e67e22;
}
.down {
  color: #c0392b;
}
.logo {
  padding: 5px 0;
  img {
    height: auto;
    max-width: 80px;
    display: block;
  }
}
.page-banner {
  padding: 10px 0;
  background-color: #403c3c;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.5) !important;
  .ant-btn-primary {
    background-color: #616161 !important;
  }
  h1 {
    margin: 0 !important;
    font-weight: 400 !important;
    font-size: 18px;
    color: white;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding-right: 15px;
  }
  .anticon.anticon-home {
    color: #fff !important;
    margin-left: 10px;
  }
  .ant-breadcrumb {
    span {
      color: white;

      a {
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
    .ant-breadcrumb-separator {
      color: #616161 !important;
    }
  }
}
.bannerBg {
  position: absolute;
  top: 0;
  left: 0;
  height: 500px;
}
.banner {
  height: 550px;
  background-size: cover;
  background-position: bottom center;
  h1 {
    font-size: 32px;
    color: white;
    margin-bottom: 0px;
  }
  h3 {
    font-style: italic;
    font-size: 16px;
    color: #c8c8c8;
  }
  p {
    color: white;
  }
  img {
    max-width: 285px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}
.ant-descriptions-bordered {
  .ant-descriptions-view {
    > table {
      table-layout: fixed !important;
    }
  }
}
.banner.login {
  height: calc(100vh - 257px);
}
.banner.page {
  height: 100px;
  background-position: center bottom;
}
.banner.page.page {
  height: 150px;
  background-position: center center;
}
.banner.page.whitelist {
  height: 140px;
  background-position: center center;
  background-color: black;
}
.banner.page.whitelist2 {
  height: 450px;
  background-position: center center;
  background-color: black;
}
.ql-editor {
  min-height: 400px;
  img {
    display: block;
    max-width: 100% !important;
    height: auto !important;
  }
  > * {
    cursor: text;
  }
  p {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ol {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    padding-left: 1.5em;
    > li {
      list-style-type: none;
    }
    li {
      &:not(.ql-direction-rtl) {
        padding-left: 1.5em;
      }
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-0;
      &:before {
        content: counter(list-0, decimal) ". ";
      }
    }
    li.ql-direction-rtl {
      padding-right: 1.5em;
    }
    li.ql-indent-1 {
      counter-increment: list-1;
      counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-1, lower-alpha) ". ";
      }
    }
    li.ql-indent-2 {
      counter-increment: list-2;
      counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-2, lower-roman) ". ";
      }
    }
    li.ql-indent-3 {
      counter-increment: list-3;
      counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-3, decimal) ". ";
      }
    }
    li.ql-indent-4 {
      counter-increment: list-4;
      counter-reset: list-5 list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-4, lower-alpha) ". ";
      }
    }
    li.ql-indent-5 {
      counter-increment: list-5;
      counter-reset: list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-5, lower-roman) ". ";
      }
    }
    li.ql-indent-6 {
      counter-increment: list-6;
      counter-reset: list-7 list-8 list-9;
      &:before {
        content: counter(list-6, decimal) ". ";
      }
    }
    li.ql-indent-7 {
      counter-increment: list-7;
      counter-reset: list-8 list-9;
      &:before {
        content: counter(list-7, lower-alpha) ". ";
      }
    }
    li.ql-indent-8 {
      counter-increment: list-8;
      counter-reset: list-9;
      &:before {
        content: counter(list-8, lower-roman) ". ";
      }
    }
    li.ql-indent-9 {
      counter-increment: list-9;
      &:before {
        content: counter(list-9, decimal) ". ";
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    padding-left: 1.5em;
    > li {
      list-style-type: none;
      &::before {
        content: "\2022";
      }
    }
    li {
      &:not(.ql-direction-rtl) {
        padding-left: 1.5em;
      }
    }
    li.ql-direction-rtl {
      padding-right: 1.5em;
    }
  }
  pre {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  blockquote {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h1 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h2 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h3 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h4 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h5 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  h6 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ul[data-checked="true"] {
    pointer-events: none;
    > li {
      * {
        pointer-events: all;
      }
      &::before {
        color: #777;
        cursor: pointer;
        pointer-events: all;
        content: "\2611";
      }
    }
  }
  ul[data-checked="false"] {
    pointer-events: none;
    > li {
      * {
        pointer-events: all;
      }
      &::before {
        color: #777;
        cursor: pointer;
        pointer-events: all;
        content: "\2610";
      }
    }
  }
  li {
    &::before {
      display: inline-block;
      white-space: nowrap;
      width: 1.2em;
    }
    &:not(.ql-direction-rtl) {
      &::before {
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
      }
    }
  }
  li.ql-direction-rtl {
    &::before {
      margin-left: 0.3em;
      margin-right: -1.5em;
    }
  }
  .ql-indent-1 {
    &:not(.ql-direction-rtl) {
      padding-left: 3em;
    }
  }
  li.ql-indent-1 {
    &:not(.ql-direction-rtl) {
      padding-left: 4.5em;
    }
  }
  .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }
  li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }
  .ql-indent-2 {
    &:not(.ql-direction-rtl) {
      padding-left: 6em;
    }
  }
  li.ql-indent-2 {
    &:not(.ql-direction-rtl) {
      padding-left: 7.5em;
    }
  }
  .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }
  li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }
  .ql-indent-3 {
    &:not(.ql-direction-rtl) {
      padding-left: 9em;
    }
  }
  li.ql-indent-3 {
    &:not(.ql-direction-rtl) {
      padding-left: 10.5em;
    }
  }
  .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }
  li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }
  .ql-indent-4 {
    &:not(.ql-direction-rtl) {
      padding-left: 12em;
    }
  }
  li.ql-indent-4 {
    &:not(.ql-direction-rtl) {
      padding-left: 13.5em;
    }
  }
  .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }
  li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }
  .ql-indent-5 {
    &:not(.ql-direction-rtl) {
      padding-left: 15em;
    }
  }
  li.ql-indent-5 {
    &:not(.ql-direction-rtl) {
      padding-left: 16.5em;
    }
  }
  .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }
  li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }
  .ql-indent-6 {
    &:not(.ql-direction-rtl) {
      padding-left: 18em;
    }
  }
  li.ql-indent-6 {
    &:not(.ql-direction-rtl) {
      padding-left: 19.5em;
    }
  }
  .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }
  li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }
  .ql-indent-7 {
    &:not(.ql-direction-rtl) {
      padding-left: 21em;
    }
  }
  li.ql-indent-7 {
    &:not(.ql-direction-rtl) {
      padding-left: 22.5em;
    }
  }
  .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }
  li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }
  .ql-indent-8 {
    &:not(.ql-direction-rtl) {
      padding-left: 24em;
    }
  }
  li.ql-indent-8 {
    &:not(.ql-direction-rtl) {
      padding-left: 25.5em;
    }
  }
  .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }
  li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }
  .ql-indent-9 {
    &:not(.ql-direction-rtl) {
      padding-left: 27em;
    }
  }
  li.ql-indent-9 {
    &:not(.ql-direction-rtl) {
      padding-left: 28.5em;
    }
  }
  .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }
  li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }
  .ql-video {
    display: block;
    max-width: 100%;
  }
  .ql-video.ql-align-center {
    margin: 0 auto;
  }
  .ql-video.ql-align-right {
    margin: 0 0 0 auto;
  }
  .ql-bg-black {
    background-color: #000;
  }
  .ql-bg-red {
    background-color: #e60000;
  }
  .ql-bg-orange {
    background-color: #f90;
  }
  .ql-bg-yellow {
    background-color: #ff0;
  }
  .ql-bg-green {
    background-color: #008a00;
  }
  .ql-bg-blue {
    background-color: #06c;
  }
  .ql-bg-purple {
    background-color: #93f;
  }
  .ql-color-white {
    color: #fff;
  }
  .ql-color-red {
    color: #e60000;
  }
  .ql-color-orange {
    color: #f90;
  }
  .ql-color-yellow {
    color: #ff0;
  }
  .ql-color-green {
    color: #008a00;
  }
  .ql-color-blue {
    color: #06c;
  }
  .ql-color-purple {
    color: #93f;
  }
  .ql-font-serif {
    font-family: Georgia, Times New Roman, serif;
  }
  .ql-font-monospace {
    font-family: Monaco, Courier New, monospace;
  }
  .ql-size-small {
    font-size: 0.75em;
  }
  .ql-size-large {
    font-size: 1.5em;
  }
  .ql-size-huge {
    font-size: 2.5em;
  }
  .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
  .ql-align-right {
    text-align: right;
  }
}
.ql-editor.homepage {
  min-height: auto !important;
}
.pledge.end {
  border-left: 4px solid #e74c3c !important;
}
.pledge.start {
  border-left: 4px solid #27ae60 !important;
}
.pledge.payment {
  border-left: 4px solid #2980b9 !important;
}
#rules {
  li {
    margin: 9px 0;
  }
  P {
    margin: 9px 0;
  }
  h3 {
    a {
      color: #282828 !important;
    }
  }
}
.mobileToggleNavigation {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  line-height: 1;
  padding: 0;
  margin: 0 20px;
  border: 0;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
#whitelist_nav {
  ul {
    li {
      a.apply {
        border-radius: 3px !important;
        margin: 0 10px;
        font-weight: 600;
        display: inline;
      }
      a {
        padding: 0;
        color: white;
        transition: all 0.3s;
        padding: 7px 13px;
        font-size: 13px;
        &:hover {
          color: #ccc;
        }
      }
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  width: 100%;
  z-index: 999;
  top: 58px;
  background-color: black;
  img {
    max-height: 45px;
    padding: 5px 0;
    width: auto;
  }
  div {
    align-self: center;
  }
}
.login {
  h1 {
    color: #282828;
  }
  P {
    color: #282828;
  }
}
footer {
  background-color: rgb(32, 32, 32);
  padding: 35px 20px;
  color: white;
  span.title {
    font-weight: 600;
    min-height: 1rem;
    display: block;
    margin-bottom: 15px;
  }
  .copyright {
    font-size: 13px;
    display: block;
    font-weight: 500;
  }
  .additionalCopyright {
    font-size: 8px;
    color: #ccc;
    line-height: 14px !important;
    display: block;
  }
  .cityStatus {
    display: flex;
    margin: 10px 0;
    font-size: 14px;
    color: #ccc;
    span {
      font-size: 10px;
      margin-left: 5px;
      color: #ccc;
    }
  }
  .anticon-check-circle {
    svg {
      fill: #27ae60;
      font-size: 16px;
      margin-right: 5px;
      margin-left: 0 !important;
    }
  }
  .anticon-close-circle {
    svg {
      fill: #c0392b;
      font-size: 16px;
      margin-right: 5px;
      margin-left: 0 !important;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      a {
        color: #ccc;
        text-decoration: none;
        line-height: 1.8;
        transition: all 0.3s;
        font-size: 13px;
        &:hover {
          color: white;
        }
      }
    }
  }
  img {
    max-width: 50%;
  }
}
hr {
  border-top: 0.5px solid #eaeaea;
  margin-top: 30px;
}
.mainContentWrapper {
  h2 {
    text-align: center;
    color: #282828;
    display: block;
    margin: 40px 0;
    position: relative;
  }
  p {
    line-height: 1.6;
    font-size: 14px;
  }
  ul {
    li {
      line-height: 1.8;
      font-size: 14px;
    }
  }
}
.previewImage {
  height: 150px;
  background-size: cover;
  background-position: bottom center;
  a {
    color: white;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
    font-size: 14px;
    transition: all 0.3s;
    text-decoration: none;
  }
}
.imagePromo {
  margin-top: -20px;
  background-color: white;
  padding: 13px;
  border-radius: 3px;
  box-shadow: 0 2px rgb(226, 226, 226);
  transition: all 0.3s;
  &:hover {
    margin-top: -25px;
    margin-bottom: 5px;
  }
  .btn {
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
    display: block;
    text-align: center;
    padding: 8px;
  }
}
.ant-btn.discord {
  background-color: #7289da;
  border-color: #7289da;
  color: white;
  &:hover {
    background-color: #667ac0;
    border-color: #667ac0;
    color: white;
  }
}
.btn.discord {
  background-color: #7289da;
  border-color: #7289da;
  color: white;
  &:hover {
    background-color: #667ac0;
    border-color: #667ac0;
    color: white;
  }
}
.card-table {
  .ant-card-body {
    padding: 0 !important;
  }
  .ant-pagination.mini {
    margin: 16px !important;
  }
}
.discordAlert {
  background-color: #7289da !important;
  color: white !important;
  border-color: #667ac0 !important;
  img {
    max-width: 60px;
    height: auto;
  }
  .ant-alert-message {
    color: white !important;
    font-weight: 500;
  }
  .anticon.ant-alert-icon {
    color: white !important;
    margin-top: 6px;
    font-size: 40px;
  }
}
.ant-notification.ant-notification-topRight {
  z-index: 9999999999 !important;
}
.ant-upload-list.ant-upload-list-text {
  margin-bottom: 15px;
}
.discordAlert.confirm {
  padding: 5px;
  margin-top: 10px;
  img {
    border-radius: 2px;
    margin-right: 10px;
  }
  .ant-alert-content {
    margin-top: 2px;
  }
}
.indexServerStatus {
  margin-top: 10px;
  color: #ccc;
  display: block;
  font-size: 13px;
}
* {
  box-sizing: inherit;
  &::before {
    box-sizing: inherit;
  }
  &::after {
    box-sizing: inherit;
  }
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #439aa4;
    }
  }
  .ant-tabs-tab {
    &:hover {
      color: #33767e;
    }
  }
  .ant-tabs-ink-bar {
    background: #439aa4;
  }
}

.news-image {
  height: 200px;
  background-size: cover;
  background-position: center center;
}

h2.news {
  font-size: 40px !important;
  margin: 0;
}

h3.news {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4) !important;
  margin: 0;
  padding: 0;
  line-height: 20px;
}

.news-items {
  transition: all 0.3s;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  display: block;

  &.light {
    background-color: #fff;
    border: 1px solid #f0f0f0;

    .title {
      color: #282828;
    }

    .description {
      color: rgba(0, 0, 0, 0.7);
    }

    &:hover {
      background-color: rgb(245, 245, 245) !important;
    }

    .date {
      color: #ccc !important;
    }
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 2px;
  }
  .categories {
    padding: 10px 0;
  }
  .title {
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    color: white;
  }
  .description {
    color: #ccc;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    color: rgb(47, 108, 115) !important;
    font-size: 11px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container-md {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container-lg {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
  > .col {
    padding-right: 0;
    padding-left: 0;
  }
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-sm-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-7 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-10 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-11 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-7 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-10 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-11 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-7 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-10 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-11 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-lg-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-7 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-10 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-11 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.row-cols-1 {
  > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row-cols-2 {
  > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.row-cols-3 {
  > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
.row-cols-4 {
  > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.row-cols-5 {
  > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.row-cols-6 {
  > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
.order-first {
  -ms-flex-order: -1;
  order: -1;
}
.order-last {
  -ms-flex-order: 13;
  order: 13;
}
.order-0 {
  -ms-flex-order: 0;
  order: 0;
}
.order-1 {
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -ms-flex-order: 4;
  order: 4;
}
.order-5 {
  -ms-flex-order: 5;
  order: 5;
}
.order-6 {
  -ms-flex-order: 6;
  order: 6;
}
.order-7 {
  -ms-flex-order: 7;
  order: 7;
}
.order-8 {
  -ms-flex-order: 8;
  order: 8;
}
.order-9 {
  -ms-flex-order: 9;
  order: 9;
}
.order-10 {
  -ms-flex-order: 10;
  order: 10;
}
.order-11 {
  -ms-flex-order: 11;
  order: 11;
}
.order-12 {
  -ms-flex-order: 12;
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.pr-4 {
  padding-right: 1.5rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pr-5 {
  padding-right: 3rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.pl-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1 {
  margin-top: -0.25rem !important;
}
.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}
.mr-n1 {
  margin-right: -0.25rem !important;
}
.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}
.mb-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2 {
  margin-top: -0.5rem !important;
}
.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
.mr-n2 {
  margin-right: -0.5rem !important;
}
.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}
.mb-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3 {
  margin-top: -1rem !important;
}
.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
.mr-n3 {
  margin-right: -1rem !important;
}
.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
.mb-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4 {
  margin-top: -1.5rem !important;
}
.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
.mr-n4 {
  margin-right: -1.5rem !important;
}
.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
.mb-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5 {
  margin-top: -3rem !important;
}
.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}
.mr-n5 {
  margin-right: -3rem !important;
}
.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}
.mb-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.idCard {
  background-color: #ecf0f1;
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0 2px rgb(226, 226, 226);
}
.profileCopy {
  font-size: 10px !important;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: black;
  }
}
.profileCard {
  display: flex;
  margin: 8px 0;
  background-color: white;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 2px rgb(226, 226, 226);
  .caption {
    font-weight: 500;
    margin: 0 10px;
  }
  i {
    color: #439aa4;
    width: 1rem;
    text-align: center;
    align-self: center;
    font-size: 16px;
  }
}
.steamLink {
  color: black;
  transition: all 0.3s;
  &:hover {
    color: #3a7295;
  }
}
table.fancy {
  border-color: grey;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  thead {
    th {
      text-align: left;
      font-weight: 500;
      color: #757575;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  th {
    padding: 8px;
  }
  td {
    padding: 8px;
    font-weight: 300;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  tr {
    transition: all 0.3s;
    &:hover {
      font-weight: 300;
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
  .actions {
    i {
      margin: 3px;
    }
  }
  .id {
    font-weight: bold;
  }
}
.status {
  span.disabled {
    color: #ccc !important;
  }
  .ban {
    color: #c0392b;
  }
  .discord {
    color: #7289da;
  }
  .steam {
    color: #1b2838;
  }
}
.slide-container {
  width: 70%;
  margin: auto;
}
h3 {
  text-align: center;
}
.each-slide {
  > div {
    background-size: cover;
    background-position: center center;
    height: 550px;
  }
}
.ant-btn.ant-btn-background-ghost {
  background-color: rgba(0, 0, 0, 0.2) !important;
  &:hover {
    background-color: white !important;
    border-color: white !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }
}
.each-fade {
  display: flex;
  .image-container {
    width: 75%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}
.job_logo {
  height: auto !important;
  max-height: 130px !important;
  max-width: 140px !important;
}
.ant-dropdown-menu-item {
  a.active {
    color: #439aa4 !important;
  }
}
.ant-descriptions-item-label {
  font-weight: 600 !important;
}
.central-spin {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  left: 0;
  top: 0;
  .ant-spin {
    align-self: center;
  }
}
.question.error {
  border: 1px solid #c0392b !important;
}
.required {
  color: #c0392b;
}

.projectTile {
  border-radius: 3px;
  margin-bottom: 15px !important;
}

.projectTileImage {
  height: 270px;
  border-radius: 3px;
  background-size: cover;
  background-position: center center;
  padding: 10px;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.site-badge-count-4 {
  .ant-badge-count {
    background-color: #fff;
    color: #999;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
    .ant-scroll-number-only-unit {
      font-size: 12px !important;
    }
  }
}
.streamCard {
  margin-bottom: 15px !important;
  .ant-card-body {
    padding: 10px !important;
  }
}
.ant-card {
  border-radius: 5px !important;
  border: none;
  box-shadow: none !important;
}
.ant-card-head-title {
  .anticon {
    color: #439aa4 !important;
  }
}
.ant-menu-inline {
  .ant-menu-item {
    &::after {
      border-color: #439aa4 !important;
    }
  }
}

.ant-btn[disabled] {
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: none !important;
    color: rgba(255, 255, 255, 0.3);
  }
  &:focus {
    background-color: rgba(255, 255, 255, 0.1);
    border: none !important;
    color: rgba(255, 255, 255, 0.3);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.1);
    border: none !important;
    color: rgba(255, 255, 255, 0.3);
  }
}
.survey-avatar {
  max-width: 80px;
}
.ant-breadcrumb {
  a {
    .anticon-home {
      color: #439aa4 !important;
    }
  }
}
.questionAnswer {
  margin-bottom: 5px;
  display: block;
}
.supporterTiers {
  margin-bottom: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  img {
    display: block;
    max-width: 80%;
    margin: 0 auto;
    max-height: 125px;
  }
  .ant-card-body {
    height: 100%;
    padding-top: 5px;
  }
  .ant-card-head-title {
    text-align: center;
  }
  .ant-card-head {
    border-bottom: 0;
  }
  button {
    display: table;
    margin: 2.5px;
  }
  .price {
    font-size: 34px;
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
  }
  .priceOccur {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: rgb(136, 136, 136);
    margin-bottom: 10px;
  }
  .discordIcon {
    color: #7289da;
    margin-right: 5px;
    align-self: center;
  }
  .discord {
    margin: 0px auto 20px auto;
    font-weight: 600;
    text-align: center;
  }
}
.guide {
  h2 {
    text-align: left !important;
    margin: 10px 0 !important;
  }
  h3 {
    text-align: left !important;
  }
}
.grey-card {
  background-color: rgba(0, 0, 0, 0.03) !important;
  margin-bottom: 10px !important;
}
.servicePage {
  height: 100%;
  .anticon-check-circle {
    color: #27ae60;
    font-size: 26px;
  }
  .anticon-close-circle {
    color: #e74c3c;
    font-size: 26px;
  }
}
.cityStatusTitle {
  margin-bottom: 3px;
  span {
    font-weight: 600;
    margin-right: 5px;
  }
}
ul.servicesServices {
  margin-left: 17px !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}
.servicesServices {
  li {
    margin: 0 !important;
    color: rgb(0, 0, 0, 0.6);
  }
}
.manage-logo {
  max-width: 200px;
  height: auto;
  background-color: black;
}
body::selection {
  background: #439aa4 !important;
  color: white !important;
}
body::-moz-selection {
  background: #439aa4 !important;
  color: white !important;
}
.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
.liveStatus {
  text-transform: uppercase;
  border-radius: 3px;
  background-color: #c0392b;
  color: white;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-block !important;
  width: auto !important;
  padding: 1px 4px;
}
.contentCreator {
  position: absolute;
  bottom: 80px;
  right: 10px;
  display: inline-block !important;
  width: auto !important;
  font-size: 24px;
  color: #e6f7ff !important;
  text-shadow: 1px 1px #91d5ff !important;
  .anticon.anticon-star {
    color: gold;
  }
}
.liveViewers {
  color: white;
  font-size: 12px;
  letter-spacing: 0.5px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block !important;
  width: auto !important;
  span {
    align-self: flex-start;
  }
}
.streamTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
}
.streamCard.content-creator {
  background-color: #e6f7ff;
  border-color: #91d5ff !important;
}
.paypal {
  margin-top: 1.5px;
  margin-right: 5px;
}
.userDropdown {
  min-width: 160px !important;
}
.termsAndConditions {
  background-color: #eceef5;
  padding: 10px 15px;
  border-radius: 3px;
}
.termsLabel {
  font-weight: bold;
  margin-right: 7px;
}
.whitelistMobileButton {
  background-color: transparent;
  border-radius: 0 !important;
  box-shadow: none !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 12px;
  margin-top: 2px;
  display: block;
  &:focus {
    background-color: transparent;
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 12px;
    margin-top: 2px;
    display: block;
  }
}
.supporterButton {
  max-height: auto !important;
  height: auto !important;
  display: flex !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  span {
    align-self: center;
  }
  .anticon {
    font-size: 60px;
  }
}
.ql-editor.ql-blank {
  &::before {
    color: rgba(0, 0, 0, 0.6);
    content: attr(data-placeholder);
    font-style: italic;
    left: 15px;
    pointer-events: none;
    position: absolute;
    right: 15px;
  }
}
.applicationsModal {
  .ant-descriptions-item-container {
    display: block !important;
    width: 100% !important;
    .ant-descriptions-item-content {
      display: block !important;
      width: 100% !important;
    }
    .ant-descriptions-item-label {
      display: block !important;
      width: 100% !important;
    }
  }
  .ant-modal-footer {
    text-align: left;
  }
}
.green {
  color: #27ae60 !important;
}
.red {
  color: #c0392b !important;
}
.paymentMethod {
  max-width: 150px !important;
  height: auto !important;
  display: block;
  align-self: center;
}
.paypalButton {
  background-color: #00457c !important;
}
.stripeButton {
  background-color: #5469d4 !important;
  border-radius: 3px !important;
}
.supporterManagerStripe {
  color: #5469d4 !important;
}
.supporterManagerPayPal {
  color: #00457c !important;
}
.ant-menu-item.giftCodeMenu {
  background-color: rgba(46, 204, 113, 0.1);
}
.ant-statistic-content-suffix {
  float: right;
  font-size: 16px;
  margin-top: 8px;
}
.charDob {
  color: #ccc;
  font-size: 0.8rem;
  margin-left: 10px;
}
#survey-wrap {
  width: 100%;
  display: block;
}
#survey-alert {
  background-color: #27ae60;
  width: 100%;
  display: block;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  padding: 5px;
  transition: all 0.3s;
  .anticon {
    color: white;
  }
  a {
    color: white;
  }
}
.historic-tickets {
  .ant-list-item-action {
    li {
      padding: 0 !important;
    }
  }
  .ant-list-item {
    transition: all 0.3s;
    padding: 5px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
  .ant-list-item-meta-description {
    font-size: 12px;
  }
}
.user-tickets {
  .ant-list-item-action {
    li {
      padding: 0 !important;
    }
  }
  .ant-list-item {
    transition: all 0.3s;
    padding: 5px 10px !important;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
  .ant-list-item-meta-description {
    font-size: 12px;
  }
}
.ticket-card .ant-card-body {
  padding: 10px !important;
}
#survey-alert.closed {
  height: 0;
  padding: 0;
}
#home-video {
  margin-top: -150px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.test {
  margin-top: -900px;
}
.supportTicket.closed.ant-card {
  background-color: #439aa4 !important;
  color: white !important;
  .ant-card-head-title {
    color: white !important;
  }
  .anticon {
    color: white !important;
  }
}
.supportTicket.closed {
  .ant-card-body {
    padding: 0 !important;
  }
}
.twitchStream {
  color: #6441a5;
  height: 70px;
  width: 70px;
}
.ant-comment.author {
  background-color: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 80%;
  padding-left: 15px;
  border-radius: 4px;
  .ant-comment-content-author-time {
    color: #282828 !important;
  }
  .ant-comment-content-author-name {
    color: #282828 !important;
  }
}
.ant-comment.response {
  background-color: #eaf3f4;
  border: 1px solid #c6e0e3;
  width: 80%;
  padding-left: 15px;
  border-radius: 4px;
  .ant-comment-content-author-time {
    color: #282828 !important;
  }
  .ant-comment-content-author-name {
    color: #282828 !important;
  }
}
.ant-comment-content {
  margin-right: 10px;
}
.sidebarHeader {
  font-weight: 500;
  margin-bottom: 5px;
}
.sidebarItem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.ant-comment-content-detail {
  img {
    display: block;
    max-width: 100%;
  }
}
.relatedBanInfo {
  .ant-descriptions-item-label {
    background-color: rgba(0, 0, 0, 0.02) !important;
    width: 200px;
  }
  table {
    border-color: rgba(0, 0, 0, 0.05) !important;
    padding: 3px 10px !important;
  }
  td {
    border-color: rgba(0, 0, 0, 0.05) !important;
    padding: 3px 10px !important;
  }
  th.ant-descriptions-item-label {
    border-color: rgba(0, 0, 0, 0.05) !important;
    padding: 3px 10px !important;
  }
  tr.ant-descriptions-row {
    border-color: rgba(0, 0, 0, 0.05) !important;
    padding: 3px 10px !important;
  }
  .ant-descriptions-bordered {
    .ant-descriptions-view {
      border-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
}
.author {
  b {
    font-weight: 600 !important;
    margin: 5px 0 !important;
    display: block;
  }
}
.response {
  b {
    font-weight: 600 !important;
    margin: 5px 0 !important;
    display: block;
  }
}
@media (max-width: 1600px) {
  .bannerBg {
    height: 550px;
    height: 550px !important;
    left: -11% !important;
  }
  .each-slide {
    > div {
      height: 550px !important;
    }
  }
}
@media (max-width: 1400px) {
  .bannerBg {
    left: -20% !important;
  }
}
@media (max-width: 1250px) {
  .bannerBg {
    left: -30% !important;
  }
}
@media (max-width: 1000px) {
  .bannerBg {
    left: -40% !important;
  }
}
@media (min-width: 768px) {
  .mobileToggleNavigation {
    display: none;
  }
  .container {
    max-width: 100%;
    max-width: 100%;
  }
  .container-sm {
    max-width: 100%;
  }
  .container-md {
    max-width: 100%;
  }
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 {
    > * {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .row-cols-md-2 {
    > * {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .row-cols-md-3 {
    > * {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
  .row-cols-md-4 {
    > * {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .row-cols-md-5 {
    > * {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  .row-cols-md-6 {
    > * {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .mr-md-3 {
    margin-right: 1rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .mr-md-5 {
    margin-right: 3rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .pr-md-3 {
    padding-right: 1rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pr-md-5 {
    padding-right: 3rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .mr-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .mr-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .mr-md-n3 {
    margin-right: -1rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .mr-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mr-md-n5 {
    margin-right: -3rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
}
@media (max-width: 768px) {
  .page-banner {
    h1 {
      border-right: none !important;
    }
  }
  .ant-card-head-title {
    width: 100%;
    overflow: visible;
    flex: auto;
    white-space: normal;
  }
  .userDropdown {
    min-width: 100% !important;
    left: 0 !important;
  }
  .staffDropdown {
    min-width: 100% !important;
    left: 0 !important;
  }
  .cardToggler {
    img {
      width: 100%;
      // max-height: none !important;
      height: auto !important;
    }
  }
  .banner {
    min-height: none !important;
  }
  .imagePromo {
    margin-top: 0 !important;
  }
  ul.navbar {
    background-color: #fff;
    position: absolute;
    top: 58px;
    left: 0;
    width: 100%;
    z-index: 100;
    .anticon.anticon-down {
      display: none;
    }
    li {
      display: block;
      width: 100%;
      margin: 0;
      a {
        padding: 10px;
        text-align: center;
        color: black;
        display: block;
        border-radius: 0 !important;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
  header {
    ul {
      li.facebookNav {
        a {
          justify-content: center;
        }
      }
      li.twitterNav {
        a {
          justify-content: center;
        }
      }
      li.linkedinNav {
        a {
          justify-content: center;
        }
      }
      li.instagramNav {
        a {
          justify-content: center;
        }
      }
      li.phoneNav {
        a {
          justify-content: center;
        }
      }
      li.mailNav {
        a {
          justify-content: center;
        }
      }
      li.whatsappNav {
        a {
          justify-content: center;
        }
      }
    }
  }
  #whitelist_nav {
    transition: all 0.3s;
    ul {
      position: absolute !important;
      width: 100%;
      left: 0;
      margin-top: 10px !important;
      transition: all 0.3s;
      li {
        &:first-of-type {
          margin-top: 4px;
        }
        display: block;
        align-self: center;
        padding: 5px !important;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        a {
          text-align: center;
          width: 100%;
          display: block !important;
          line-height: 12px !important;
          margin: 0 !important;
          padding: 12px !important;
        }
      }
    }
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
    max-width: 100%;
  }
  .container-sm {
    max-width: 100%;
  }
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 {
    > * {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .row-cols-sm-2 {
    > * {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .row-cols-sm-3 {
    > * {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
  .row-cols-sm-4 {
    > * {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .row-cols-sm-5 {
    > * {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  .row-cols-sm-6 {
    > * {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .mr-sm-5 {
    margin-right: 3rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pr-sm-5 {
    padding-right: 3rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .mr-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .mr-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .mr-sm-n3 {
    margin-right: -1rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .mr-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mr-sm-n5 {
    margin-right: -3rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
    max-width: 960px;
  }
  .container-sm {
    max-width: 960px;
  }
  .container-md {
    max-width: 960px;
  }
  .container-lg {
    max-width: 960px;
  }
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 {
    > * {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .row-cols-lg-2 {
    > * {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .row-cols-lg-3 {
    > * {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
  .row-cols-lg-4 {
    > * {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .row-cols-lg-5 {
    > * {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  .row-cols-lg-6 {
    > * {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .mr-lg-3 {
    margin-right: 1rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .mr-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .mr-lg-5 {
    margin-right: 3rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .pr-lg-3 {
    padding-right: 1rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .pr-lg-4 {
    padding-right: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pr-lg-5 {
    padding-right: 3rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .mr-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .mr-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .mr-lg-n3 {
    margin-right: -1rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .mr-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mr-lg-n5 {
    margin-right: -3rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
    max-width: 1140px;
  }
  .container.smallContainer {
    max-width: 800px;
  }
  .container-sm {
    max-width: 1140px;
  }
  .container-md {
    max-width: 1140px;
  }
  .container-lg {
    max-width: 1140px;
  }
  .container-xl {
    max-width: 1140px;
  }
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 {
    > * {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .row-cols-xl-2 {
    > * {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .row-cols-xl-3 {
    > * {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
  .row-cols-xl-4 {
    > * {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .row-cols-xl-5 {
    > * {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  .row-cols-xl-6 {
    > * {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .mr-xl-3 {
    margin-right: 1rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .mr-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .mr-xl-5 {
    margin-right: 3rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .pr-xl-1 {
    padding-right: 0.25rem !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .pr-xl-2 {
    padding-right: 0.5rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .pr-xl-3 {
    padding-right: 1rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .pr-xl-4 {
    padding-right: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pr-xl-5 {
    padding-right: 3rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .mr-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .mr-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .mr-xl-n3 {
    margin-right: -1rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .mr-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mr-xl-n5 {
    margin-right: -3rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.dark-mode {
  background-color: #0d1117;
  .ant-select .ant-select-arrow {
    color: #439aa4;
  }
  .ant-comment-content-author-name > * {
    color: #ccc !important;
  }
  .news-items {
    background-color: #161b22 !important;
    border: none;

    .title {
      color: #439aa4;
    }
    .description {
      color: #ccc;
    }
  }
  .news-items:hover {
    background-color: #0b0e11 !important;
  }
  h3.news {
    color: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-comment-actions span {
    color: #ccc;
  }
  .ant-alert {
    background-color: #141414 !important;
    color: #ccc !important;
  }
  .ant-alert-message {
    background-color: #141414 !important;
    color: #ccc !important;
  }
  .ant-pagination-item {
    a {
      color: #ccc;
    }
  }
  .ant-pagination-next {
    button {
      color: #ccc;
    }
  }
  .ant-pagination-item-container {
    svg {
      color: #ccc;
    }
  }
  .login {
    h1 {
      color: #439aa4 !important;
    }
  }
  .ant-pagination-item.ant-pagination-item-active {
    background-color: #439aa4;
    border-color: #439aa4;
    color: white;
  }
  .ant-menu {
    background: rgba(255, 255, 255, 0.1);
    color: #ccc;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #439aa4;
    color: white;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: rgba(0, 0, 0, 0.3);
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-color: rgba(0, 0, 0, 0.2) !important;
  }
  .ant-table {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #ccc !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
    &.ant-table-bordered > .ant-table-container {
      border: 0 !important;
    }
    .ant-table-thead {
      > tr {
        > th {
          background-color: transparent;
          color: white;
          border: transparent !important;
        }
      }
    }
  }
  .ant-table-cell {
    border-color: rgba(255, 255, 255, 0.1) !important;
  }
  .ant-table-thead {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .ant-card-body {
    color: #ccc;
  }
  .ant-input[disabled] {
    color: rgba(255, 255, 255, 0.25) !important;
  }
  .ant-card.ant-card-bordered {
    background-color: #161b22 !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .welcomeText {
    background-color: #161b22;
    padding-top: 20px;
    margin-top: 20px;
  }
  h3 {
    color: #ccc;
  }

  .imagePromo {
    background-color: #161b22 !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .lightSection {
    background-color: #1f1f1f;
    .card {
      box-shadow: none !important;
      background-color: #141414;
      color: #ccc !important;
      .cardContent {
        border-top: 0px;
      }
      h4 {
        color: rgba(255, 255, 255, 0.4) !important;
      }
    }
  }
  .ant-collapse-header {
    color: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-collapse-item {
    label {
      color: #ccc;
    }
    color: #ccc !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  .ant-collapse-borderless {
    color: #ccc !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  input {
    background-color: #3c3c3c !important;
    border-color: #3c3c3c !important;
    color: #ccc !important;
  }
  .ant-picker {
    background-color: #3c3c3c !important;
    border-color: #3c3c3c !important;
    color: #ccc !important;
  }
  .ant-select-selector {
    background-color: #3c3c3c !important;
    border-color: #3c3c3c !important;
    color: #ccc !important;
  }
  .ant-checkbox-inner {
    background-color: #3c3c3c !important;
    border-color: #3c3c3c !important;
    color: #ccc !important;
  }
  textarea {
    background-color: #3c3c3c !important;
    border-color: #3c3c3c !important;
    color: #ccc !important;
  }
  .ant-checkbox-wrapper {
    span {
      color: #ccc;
    }
  }
  .ant-radio-wrapper {
    span {
      color: #ccc;
    }
  }
  .ant-comment.author {
    .ant-comment-content-author-name {
      color: #439aa4 !important;
    }
    .ant-comment-content-author-time {
      color: #439aa4 !important;
    }
    background-color: rgba(255, 255, 255, 0.05);
  }
  .ant-comment.response {
    .ant-comment-content-author-name {
      color: #439aa4 !important;
    }
    .ant-comment-content-author-time {
      color: #439aa4 !important;
    }
    background-color: rgba(255, 255, 255, 0.2);
    border: none !important;
  }
  .sidebarHeader {
    color: #fff;
  }
  .sidebarItem {
    color: #ccc;
    border-color: rgba(255, 255, 255, 0.2);
  }
  .ant-card-actions {
    background: transparent !important;
    color: white !important;
    border-color: rgba(255, 255, 255, 0.1);
    a {
      color: #ccc !important;
    }
  }
  .ant-btn.ant-btn-background-ghost {
    background-color: transparent !important;
    color: white !important;
    border-color: white !important;
  }
  .ant-btn {
    background-color: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  .ant-tabs-tab {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-result-title {
    color: #2f6c73;
    font-weight: bold;
  }
  .ant-result-subtitle {
    color: #ccc;
  }
  .ant-list-bordered {
    border: 0 !important;
  }
  .ant-list-item {
    color: #ccc !important;
  }
  .ant-card-head-title {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-list-item-meta-title {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-card {
    .ant-card-head {
      border-color: rgba(255, 255, 255, 0.1);
    }
  }
  .ant-list-split {
    .ant-list-item {
      border-color: rgba(255, 255, 255, 0.1);
    }
  }
  .ant-statistic-title {
    color: rgba(255, 255, 255, 0.2);
  }
  .ant-alert.ant-alert-info {
    background: #1890ff;
    border-color: #1890ff;
    color: white !important;
    .ant-alert-message {
      color: white;
    }
    .ant-alert-description {
      color: white;
    }
    .ant-alert-icon {
      color: white;
    }
  }
  .ant-tag.ant-tag-blue {
    background: #1890ff;
    border-color: #1890ff;
    color: white !important;
  }
  .ant-alert.ant-alert-success {
    background: #52c41a;
    border-color: #52c41a;
    color: white !important;
    .ant-alert-message {
      color: white;
    }
    .ant-alert-description {
      color: white;
    }
    .ant-alert-icon {
      color: white;
    }
  }
  .ant-tag.ant-tag-green {
    background: #52c41a;
    border-color: #52c41a;
    color: white !important;
  }
  .ant-alert.ant-alert-error {
    background: #ff4d4f;
    border-color: #ff4d4f;
    color: white !important;
    .ant-alert-message {
      color: white;
    }
    .ant-alert-description {
      color: white;
    }
    .ant-alert-icon {
      color: white;
    }
  }
  .ant-tag.ant-tag-red {
    background: #ff4d4f;
    border-color: #ff4d4f;
    color: white !important;
  }
  .ant-statistic-content-value {
    color: #ccc;
  }
  .ant-card-meta-title {
    color: #439aa4;
  }
  .ant-card-meta-description {
    color: #ccc;
  }
  .text-center {
    color: #ccc;
  }
  h2 {
    a {
      color: #439aa4 !important;
    }
    color: #439aa4;
  }
  .ant-anchor-link-title {
    color: rgba(255, 255, 255, 0.5);
  }
  .ant-btn[disabled] {
    background-color: rgba(255, 255, 255, 0.1);
    border: none !important;
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-card-extra {
    color: #439aa4;
  }
  P {
    color: rgba(255, 255, 255, 0.4);
  }
}

.item-label {
  font-weight: 500;
  width: 200px;
  display: inline-block;
}

.hover-greyscale {
  &:hover {
    filter: grayscale(1%);
  }
}

.bgb-pink {
  font-weight: bold;
  color: #e82484;
}

.disabled-link {
  pointer-events: none;
}

.white {
  color: #fff
}